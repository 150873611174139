<template>
  <section id="user">
    <h1 class="title" id="title">Usuários</h1>
    <h2 class="subtitle">Permite realizar a manutenção do cadastro de usuários, assim como seus times, carteira, e ainda visualizar o histórico de transações</h2>

    <div>
      <b-modal
        :active.sync="loading"
        :width="400"
        :can-cancel="[false, false, false]"
      >
        <div class="modal-card" style="width: auto">
          <section class="modal-card-body">
            <div class="field" style="margin-top: 25px;">
              <p style="margin-bottom: 15px; text-align: center;">
                Carregando informações, por favor, não recarregue a página até
                que o processo seja concluído.
              </p>
              <progress class="progress is-small is-primary" max="100"
                >15%</progress
              >
            </div>
          </section>
        </div>
      </b-modal>
    </div>
    <div class="columns is-variable is-gapless has-background-bluedark2">
      <div class="column is-full">
        <div class="toolbar">
          <b-button 
            v-if="userTable" 
            type="is-danger" 
            @click="() => {filter.value = '';filter.condition = '';}"
            icon-pack="fas"
            icon-left="broom"
            outlined 
          >Limpar</b-button>
          <b-button 
            v-if="userTable" 
            :loading="loading" 
            type="is-primary" 
            @click="search()"
            icon-pack="fas"
            icon-left="search"
            style="margin-left: 15px" 
          >Buscar</b-button>
          <b-button 
            v-if="userForm && userFormAction == 'edit'" 
            type="is-success" 
            @click="updateUser()"
            icon-pack="fas"
            icon-left="edit"
          >Atualizar</b-button>
          <b-button 
            v-if="!userTable && !userForm" 
            type="is-warning" 
            outlined 
            @click="returnSelect()"
            icon-pack="fas"
            icon-left="undo-alt"
          >Voltar</b-button>
          <b-button 
            v-if="userForm" 
            type="is-danger" 
            outlined 
            @click="clearSelect()"
            icon-pack="fas"
            icon-left="times"
          >Cancelar</b-button>
        </div>
      </div>
    </div>

    <div v-if="userTable" class="columns has-background-bluedark2">
      <div class="column is-9">
        <b-field label="Usuários">
          <template slot="label">
            <p>Usuários</p>
            <small>Lista de usuários localizados pela pesquisa</small>
          </template>
          <b-table
            blueDark2
            :data="searchData"
            :loading="loading"
            paginated
            per-page="10"
            detailed
            detail-key="id"
            :show-detail-icon="true" 
          >
            <!-- :selected.sync="tableSelected"
            @select="(row, oldRow) => select(row, oldRow)" -->
            <template slot-scope="props">

              <b-table-column field="acoes" label="Ações" centered>
                <b-button type="is-primary" icon-pack="fas" size="is-small" icon-right="search" outlined @click="viewUser(props.row)"/>
                <b-button v-if="userInfo.isAdmin" type="is-warning" icon-pack="fas" size="is-small" icon-right="edit" outlined @click="editUser(props.row)"/>
                <b-button v-if="userInfo.isAdmin" type="is-info" icon-pack="fas" size="is-small" icon-right="trophy" outlined @click="teamsUser(props.row)"/>
                <b-button v-if="userInfo.isAdmin" type="is-success" icon-pack="fas" size="is-small" icon-right="money-bill-wave" outlined @click="walletUser(props.row)"/>
                <b-button v-if="userInfo.isAdmin" type="is-purple" icon-pack="fas" size="is-small" icon-right="list" outlined @click="transactionUser(props.row)"/>
              </b-table-column>

              <b-table-column field="id" label="ID" sortable>
                {{ props.row.id }}
              </b-table-column>

              <b-table-column field="nome" label="Nome" sortable>
                <span style="cursor: pointer">
                  {{ props.row.nome_completo }}
                </span>
              </b-table-column>

              <b-table-column field="email" label="Email" sortable>
                {{ props.row.email }}
              </b-table-column>

              <b-table-column field="telefone" label="Telefone" sortable>
                {{ props.row.telefone }}
              </b-table-column>

              <b-table-column field="cpf" label="CPF" sortable>
                {{ props.row.cpf }}
              </b-table-column>

              <b-table-column field="acesso" label="Acesso" centered>
                <b-tag
                  rounded
                  :type="props.row.acesso ? 'is-success' : 'is-danger'"
                  style="padding: 0; width: 1.5em !important; height: 1.5em !important; border-radius: 50%;"
                ></b-tag>
              </b-table-column>
            </template>

            <template slot="detail" slot-scope="props">
              <table>
                <tr>
                  <td><b class='is-size-6'>Endereço</b></td>
                  <td>{{props.row.endereco.logradouro}}, {{props.row.endereco.numero}} {{props.row.endereco.complemento}}</td>
                  <td><b class='is-size-6'>Plano</b></td>
                  <td>{{props.row.plano}} - {{props.row.periodo}}</td>
                  <td><b class='is-size-6'>Aprovado em</b></td>
                  <td>{{formatData(props.row.aprovacao)}}</td>
                </tr>
                <tr>
                  <td><b class='is-size-6'>Bairro</b></td>
                  <td>{{props.row.endereco.bairro}}</td>
                  <td><b class='is-size-6'>Valor</b></td>
                  <td>R$ {{props.row.planoValor.toFixed(2)}}</td>
                  <td><b class='is-size-6'>Expirado em</b></td>
                  <td>{{formatData(props.row.expiracao)}}</td>
                </tr>
                <tr>
                  <td><b class='is-size-6'>Cidade/UF</b></td>
                  <td>{{props.row.endereco.cidade}}/{{props.row.endereco.uf}}</td>
                  <td><b class='is-size-6'>Referência</b></td>
                  <td>{{props.row.referencia}}</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td><b class='is-size-6'>CEP</b></td>
                  <td>{{props.row.endereco.cep}}</td>
                  <td><b class='is-size-6'>Transação</b></td>
                  <td>R$ {{props.row.transacao}}</td>
                  <td></td>
                  <td></td>
                </tr>
              </table>
            </template>

            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                  </p>
                  <p>Sem Registros.</p>
                </div>
              </section>
            </template>
          </b-table>
        </b-field>
      </div>
      <div class="column is-3">
        <div class="columns is-multiline">
          <div class="column is-full">
            <b-field label="Condição">
              <template slot="label">
                <p>Condição</p>
                <small>Selecione um campo para pesquisa do usuário</small>
              </template>
              <b-select
                v-model="filter.condition"
                placeholder="Selecione um campo para pesquisa do usuário"
                expanded
                required
              >
                <option value="mail_pssa">Email</option>
                <option value="id_reference">ID Reference</option>
                <option value="p_nm_pssa">Nome</option>
                <option value="s_nm_pssa">Sobrenome</option>
                <option value="cpf_pssa">CPF</option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-full">
            <b-field label="Valor">
              <template slot="label">
                <p>Valor</p>
                <small>Informe todo o conteúdo ou parte dele a ser pesquisado no campo</small>
              </template>
              <b-input v-model="filter.value" @keyup.native.enter="search()" required></b-input>
            </b-field>
          </div>
        </div>
      </div>
    </div>

    <div v-if="userForm" class="columns is-multiline notification has-background-bluedark2">

      <div class="column is-full">

        <b-field grouped>

          <b-field label="Nome" expanded>
            <b-field :type="!edit.nome ? 'is-success' : 'is-dark'">
              <b-input
                v-model="editData.nome"
                expanded
                :disabled="edit.nome"
              ></b-input>
              <b-button
                @click="ableEdit('nome')"
                icon-left="edit"
                icon-pack="fas"
                type="is-info"
              />
            </b-field>
          </b-field>
          <b-field label="Sobrenome" expanded>
            <b-field :type="!edit.sobrenome ? 'is-success' : 'is-dark'">
              <b-input
                v-model="editData.sobrenome"
                expanded
                :disabled="edit.sobrenome"
              ></b-input>
              <b-button
                @click="ableEdit('sobrenome')"
                icon-left="edit"
                icon-pack="fas"
                type="is-info"
              />
            </b-field>
          </b-field>

          <b-field>
            <b-field label="Acesso da Conta">
              <b-switch
                v-model="editData.acesso"
                :true-value="true"
                :false-value="false"
                :type="(editData.acesso) ? 'is-success' : 'is-danger'"
                size="is-medium"
              >
                <b-tag v-if="editData.acesso" type="is-success" size="is-medium">Liberado</b-tag>
                <b-tag v-else type="is-danger" size="is-medium">Bloqueado</b-tag>
              </b-switch>
            </b-field>
          </b-field>
        </b-field>

        <b-field grouped>
          <b-field label="Email" expanded>
            <b-field :type="!edit.email ? 'is-success' : 'is-dark'">
              <b-input
                v-model="editData.email"
                expanded
                :disabled="edit.email"
              ></b-input>
              <b-button
                @click="ableEdit('email')"
                icon-left="edit"
                icon-pack="fas"
                type="is-info"
              />
            </b-field>
          </b-field>
          <b-field label="Cpf" expanded>
            <b-field :type="!edit.cpf ? 'is-success' : 'is-dark'">
              <b-input
                v-model="editData.cpf"
                expanded
                :disabled="edit.cpf"
                :type="edit.cpf ? 'is-info' : 'is-dark'"
              ></b-input>
              <b-button
                @click="ableEdit('cpf')"
                icon-left="edit"
                icon-pack="fas"
                type="is-info"
              />
            </b-field>
          </b-field>
          <b-field label="Senha" expanded>
            <b-field :type="!edit.senha ? 'is-success' : 'is-dark'">
              <b-input
                v-model="editData.senha"
                expanded
                :disabled="edit.senha"
                :type="edit.senha ? 'is-info' : 'is-dark'"
              ></b-input>
              <b-button
                :disabled="edit.senha"
                @click="generateSenha()"
                icon-left="sync-alt"
                icon-pack="fas"
                type="is-yellow"
              />
              <b-button
                @click="ableEdit('senha')"
                icon-left="edit"
                icon-pack="fas"
                type="is-info"
              />
            </b-field>
          </b-field>
        </b-field>

        <b-field grouped>
          <b-field label="Plano" expanded>
            <b-field :type="!edit.plano ? 'is-success' : 'is-dark'">
              <b-select
                v-model="editData.plano"
                expanded
                :disabled="edit.plano"
              >
                <option v-for="plan of plansHere" :value="plan.id" :key="'plan-choose-'+plan.id">{{ plan.periodo }}</option>
              </b-select>
              <b-button @click="ableEdit('plano')" icon-left="edit" icon-pack="fas" type="is-info"/>
            </b-field>
          </b-field>
          <b-field label="Descrição" expanded>
            <b-field type="is-dark">
              <b-input
                expanded
                :value="getDescricaoPlan"
                disabled
              ></b-input>
            </b-field>
          </b-field>
          <b-field label="Plano Valor" expanded>
            <b-field
              :type="!edit.planoValor ? 'is-success' : 'is-dark'"
            >
              <b-input
                v-model="editData.planoValor"
                expanded
                :disabled="edit.planoValor"
              ></b-input>
              <b-button
                @click="ableEdit('planoValor')"
                icon-left="edit"
                icon-pack="fas"
                type="is-info"
              />
            </b-field>
          </b-field>
          <b-field label="Referencia" expanded>
            <b-field
              :type="!edit.referencia ? 'is-success' : 'is-dark'"
            >
              <b-input
                v-model="editData.referencia"
                expanded
                :disabled="edit.referencia"
              ></b-input>
              <b-button
                @click="ableEdit('referencia')"
                icon-left="edit"
                icon-pack="fas"
                type="is-info"
              />
            </b-field>
          </b-field>
          <b-field label="Transação" expanded>
            <b-field :type="!edit.transacao ? 'is-success' : 'is-dark'">
              <b-input
                v-model="editData.transacao"
                expanded
                :disabled="edit.transacao"
              ></b-input>
              <b-button
                @click="ableEdit('transacao')"
                icon-left="edit"
                icon-pack="fas"
                type="is-info"
              />
            </b-field>
          </b-field>
        </b-field>

        <b-field grouped>
          <b-field
            label="Status Pagamento"
            :type="!edit.status ? 'is-success' : 'is-dark'"
            expanded
          >
            <b-input
              v-model="editData.status"
              :disabled="true"
            ></b-input>
          </b-field>
          <b-field label="Data de Aprovacao" expanded>
            <b-field :type="!edit.aprovacao ? 'is-success' : 'is-dark'">
              <b-datepicker
                v-model="editData.aprovacao"
                expanded
                :disabled="edit.aprovacao"
                icon="calendar-today"
              >
              </b-datepicker>
              <b-button
                @click="ableEdit('aprovacao')"
                icon-left="edit"
                icon-pack="fas"
                type="is-info"
              />
            </b-field>
          </b-field>
          <b-field label="Data de Expiracao" expanded>
            <b-field :type="!edit.expiracao ? 'is-success' : 'is-dark'">
              <b-datepicker
                v-model="editData.expiracao"
                expanded
                :disabled="edit.expiracao"
                icon="calendar-today"
              >
              </b-datepicker>
              <b-button
                @click="ableEdit('expiracao')"
                icon-left="edit"
                icon-pack="fas"
                type="is-info"
              />
            </b-field>
          </b-field>
        </b-field>

      </div>
    </div>

    <template v-if="userWallet">
      <Trades :idUser="tableSelected.id"> </Trades>
    </template>

    <template v-if="userTeams">
      <Teams :idUser="tableSelected.id"> </Teams>
    </template>

    <template v-if="userTransaction">
      <Transactions :idUser="tableSelected.id"> </Transactions>
    </template>

  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import Trades from "./Trades";
import Teams from "./Teams";
import Transactions from "./Transactions";

export default {
  name: "Admin",
  components: {
    Trades,
    Teams,
    Transactions
  },
  data() {
    return {
      userTable: false,
      userForm: false,
      userTeams: false,
      userWallet: false,
      userTransaction: false,
      userFormAction: "",
      filter: {
        condition: "",
        value: ""
      },
      loading: false,
      campos: [
        "nome",
        "sobrenome",
        "email",
        "cpf",
        "plano",
        "referencia",
        "status",
        "planoValor",
        "aprovacao",
        "expiracao",
        "senha"
      ],
      edit: {
        nome: true,
        sobrenome: true,
        email: true,
        cpf: true,
        plano: true,
        referencia: true,
        transacao: true,
        status: true,
        planoValor: true,
        aprovacao: true,
        expiracao: true,
        senha: true
      },
      searchData: [],
      tableSelected: null,
      isSwitchedCustom: "as",
      editData: {},
      loadUpbt: false,
      plansHere: [],
      getDescricaoPlan: '-'
    };
  },
  computed: {
    ...mapGetters(["userInfo", "userId"]),
  },
  created() {

    let loader = this.$loading.show()

    this.$http.get(url + "/assinaturas/plans/all").then(res => {
      if (res.data.status == "error") {
        this.$snackbar.open({
          message:
            "Nao foi possivel pegar os planos, recarregue a pagina ou nao ALTERE nenhum PLANO!",
          type: "is-danger",
          position: "is-top",
          actionText: "Ok",
          indefinite: true
        });
      } else {
        this.plansHere = res.data.data;
      }
    });

    this.userTable = true;
    loader.hide()

  },
  watch: {
    'editData.plano': {
      handler: function (n,o) {
        if (o != null) {
          let plan = this.plansHere.filter(e => e.id == n)
          if (plan.length > 0) {
            this.editData.periodo = plan[0].periodo
            this.editData.planoValor = plan[0].valor
            this.getDescricaoPlan = plan[0].descricao

            let expiracao
            if (plan[0].expiracao !== null) {
              expiracao = new Date(plan[0].expiracao)
            } else {
              expiracao = new Date()
              expiracao.setDate(expiracao.getDate() + plan[0].expiracaoDias );
            }
            this.editData.expiracao = expiracao
            this.editData.aprovacao = new Date()

            this.ableEdit('periodo')
            this.ableEdit('planoValor')
            this.ableEdit('expiracao')
            this.ableEdit('aprovacao')
          }
        }
      }
    }
  },
  methods: {
    search() {
      this.tableSelected = null;
      this.redefinir();

      this.loading = true;

      if (this.filter.condition != "" && this.filter.value != "") {
        this.$http
          .post(url + "/usuarios/" + this.userId + "/admin/search", {
            filter: {
              condition: this.filter.condition,
              value: this.filter.value
            }
          })
          .then(res => {
            if (res.data.status == "error") {
              this.$buefy.snackbar.open({
                message: "Usuario inexistente ou Dados incorretos!",
                type: "is-danger",
                position: "is-top",
                actionText: "Ok",
                indefinite: true
              });
            } else {
              this.searchData = res.data.data;
            }
            this.loading = false;
          })
          .catch(err => {
            this.$buefy.snackbar.open({
              message: "Usuario inexistente ou Dados incorretos!",
              type: "is-danger",
              position: "is-top",
              actionText: "Ok",
              indefinite: true
            });
            this.loading = false;
          })
      } else {
        this.alertCustomError();
        this.loading = false;
      }
    },
    async viewUser(user) {

      this.select(user)

      this.userTable = false
      this.userForm = true
      this.userTeams = false
      this.userWallet = false
      this.userTransaction = false
      this.userFormAction = "view"
    },
    async editUser(user) {

      this.select(user)
      
      this.userTable = false
      this.userForm = true
      this.userTeams = false
      this.userWallet = false
      this.userTransaction = false
      this.userFormAction = "edit"
    },
    async teamsUser(user) {

      this.select(user)
      
      this.userTable = false
      this.userForm = false
      this.userTeams = true
      this.userWallet = false
      this.userTransaction = false
      this.userFormAction = "teams"
    },
    async walletUser(user) {

      this.select(user)
      
      this.userTable = false
      this.userForm = false
      this.userTeams = false
      this.userWallet = true
      this.userTransaction = false
      this.userFormAction = "wallet"
    },
    async transactionUser(user) {

      this.select(user)
      
      this.userTable = false
      this.userForm = false
      this.userTeams = false
      this.userWallet = false
      this.userTransaction = true
      this.userFormAction = "transaction"
    },
    select(row) {
      this.editData = JSON.parse(JSON.stringify(row));
      this.editData.expiracao = this.editData.expiracao
        ? new Date(this.editData.expiracao)
        : new Date();
      this.editData.aprovacao = this.editData.aprovacao
        ? new Date(this.editData.aprovacao)
        : new Date();

      if (this.editData.plano)
        this.getDescricaoPlan = this.plansHere.filter(e => e.id == this.editData.plano)[0].descricao
      
      if (!this.editData.expiracao) this.ableEdit('expiracao')
      if (!this.editData.aprovacao) this.ableEdit('aprovacao')
      
      this.tableSelected = row
    },
    clearSelect() {

      this.tableSelected = null;
      this.searchData = [];
      this.editData = {};
      this.loadUpbt = false;
      
      this.userTable = true
      this.userForm = false
      this.userTeams = false
      this.userWallet = false
      this.userTransaction = false
      this.userFormAction = ""
    },
    returnSelect() {

      this.userTable = true
      this.userForm = false
      this.userTeams = false
      this.userWallet = false
      this.userTransaction = false
      this.userFormAction = ""
    },
    async updateUser() {
      
      this.loading = true
      this.loadUpbt = true;

      let data = await this.compareData();
      let body = {
        data: data,
        user: {
          id: this.tableSelected.id
        },
        author: {
          id: this.userInfo.id,
          nome: this.userInfo.nome
        }
      };
      this.$http
        .put(url + "/usuarios/" + this.userId + "/admin/update", body)
        .then(res => {
          if (res.data.message != "error") {
            if (res.data.data) {
              this.$toasted.success("Usuario Atualizado!", {
                position: "top-center",
                duration: 3000
              });

              // resetando infos
              this.clearSelect()
            } else {
              this.$toasted.error("Dados iguais ao que ja estava cadastrado!", {
                position: "top-center",
                duration: 5000,
                type: 'warning'
              });
              this.loadUpbt = false;
            }
          } else {
            this.$buefy.snackbar.open({
              message:
                "Houve um problema ao tentar atualizar, Nossa equipe já foi avisada do ocorrido. Por favor tente novamente mais tarde!",
              type: "is-danger",
              position: "is-top",
              actionText: "Ok",
              indefinite: true,
              onAction: () => {
                this.$toast.open({
                  message: "Obrigado!",
                  queue: false
                });
              }
            });
            this.loadUpbt = false;
          }
          this.redefinir();
          this.loading = false
        });
    },
    async compareData() {
      let hasEdit = this.campos
        .map(el => {
          let arr = [];
          if (!this.edit[el]) {
            return {
              name: el,
              value: this.editData[el]
            };
          } else return null;
        })
        .filter(el => el != null);

      if (this.editData.plano) {
        let expiracao;

        if (this.editData.acesso && this.editData.status != "approved") {
          this.plansHere.map(el => {
            if (this.editData.plano == el.id) {
              if (el.expiracao) {
                expiracao = el.expiracao;
              } else {
                if (el.periodo == "MENSAL")
                  expiracao = moment(new Date())
                    .utc()
                    .add(30, "days")
                    .format();
                if (el.periodo == "QUINZENAL")
                  expiracao = moment(new Date())
                    .utc()
                    .add(15, "days")
                    .format();
              }
            }
          });

          hasEdit.push({
            name: "status",
            value: "approved"
          });
          hasEdit.push({
            name: "aprovacao",
            value: this.editData.aprovacao
          });
          hasEdit.push({
            name: "expiracao",
            value: expiracao
          });
        } else if (!this.editData.acesso && this.editData.status != "") {
          let date = new Date();

          hasEdit.push({
            name: "status",
            value: "blocked"
          });
          hasEdit.push({
            name: "expiracao",
            value: moment(date)
              .utc()
              .subtract(1, "days")
              .format()
          });
        }
      } else {
        this.$toasted.error(
          "Usuario nao possui plano, cuidado ao alterar os dados",
          {
            position: "top-center",
            duration: 3000,
            type: "warning"
          }
        );
      }

      return hasEdit;
    },
    redefinir() {
      this.edit = {
        nome: true,
        sobrenome: true,
        email: true,
        cpf: true,
        plano: true,
        referencia: true,
        transacao: true,
        status: true,
        planoValor: true,
        aprovacao: true,
        expiracao: true,
        senha: true
      };
    },
    ableEdit(campo) {
      if (campo == 'senha') {
        this.$buefy.dialog.confirm({
          message: 'Tem certeza que deseja alterar a senha?',
          onConfirm: () => {
            this.edit[campo] = false;
            this.generateSenha()
          }
        })
      }
      else {
        this.edit[campo] = false;
      }

      
    },
    generateSenha() {
      // let num_random = Math.floor(Math.random() * 70 + 1)
      // let default_pass = 'Escala10@' + num_random

      let num_random = Math.floor(Math.random() * 100000000 + 1)
      num_random = num_random.toString()
      num_random = num_random.substring(0,6)
      num_random = num_random.padStart(6,'0')

      let default_pass = num_random

      this.editData.senha = default_pass
    },
    alertCustomError() {
      this.$buefy.dialog.alert({
        title: "Error",
        message: "Selecione os filtros",
        type: "is-danger",
        hasIcon: true,
        icon: "times-circle",
        iconPack: "fa"
      });
    },
    formatData(data) {
      return moment(new Date(data)).format('DD/MM/YYYY - HH:mm:ss')
    }
  }
};
</script>

<style scoped>
#user .has-bd {
  border-left: 3px dotted white;
}
#user .toolbar {
  text-align: right;
  padding: 1.5em;
}
</style>
