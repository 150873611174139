<template>
  <section id="adminAddTeam">
    <div class="modal-card" style="width: auto; height: 85vh;">
      <header class="modal-card-head">
        <p class="modal-card-title">Busca de Times no CartolaFC</p>
      </header>
        <section class="modal-card-body">
          <!-- <div class="centralize">
            <img src="../../assets/cartola-fc-logo-8.png" alt="Cartola FC" width="150">
          </div> -->
          
          <div v-if="!loadProgress">
            <p style="margin: 20px; text-align: center;">
            Insira o nome do seu time para Buscá-lo.
            </p>

            <p v-if="selected.length > 0">Times selecionados: </p>
            <div class="columns is-multiline is-gapless">
              <div class="column is-one-quarter" v-for="team of selected" :key="team.time_id">
                <div class="centralize" style="padding: .5em;">
                  <img :src="team.url_escudo_svg" :alt="team.nome" style="width: 35%">
                  <p style="font-size: 0.8em; text-align: center;">{{ team.nome }}</p>
                </div>
              </div>
            </div>

            <b-field style="margin-top: 10px;" label="Insira o Nome do Time">
              <b-input
                v-model="searchTeaminput"
                type="search"
                icon-pack="fas"
                icon="search"
                @keyup.native.enter="search()">
              </b-input>
            </b-field>
            <b-field>
              <b-button @click="search()" type="is-primary" size="is-small" style="width: 100%"><b>Buscar</b></b-button>
            </b-field>

            <div v-if="showResultsFriends" class="friend-modal">
              <b-table :data="timesFriends">
                <template slot-scope="props">
                    <b-table-column field="url_escudo_svg" label="Time" width="100">
                      <div>
                        <img :src="props.row.url_escudo_svg" :alt="props.row.nome_time" width="40" class="friend-img-escudo">
                        <img :src="props.row.foto_perfil" :alt="props.row.nome_cartola" width="30" class="friend-img-perfil">
                      </div>
                    </b-table-column>

                    <b-table-column field="nome" label="Nome" width="200">
                      <p class="friend-p">{{ props.row.nome }}</p>
                      <small class="friend-small">{{ props.row.nome_cartola }}</small>
                    </b-table-column>

                    <b-table-column field="time_id" label="">
                      <b-checkbox :native-value="props.row" type="is-primary" v-model="selected"></b-checkbox>
                      <!-- <b-button @click="selectTeam(props.row, props.index)" type="is-primary" size="is-small" icon-right="check" icon-pack="fa" outlined/> -->
                    </b-table-column>

                </template>
                <template slot="empty">
                  <section class="section">
                      <div class="content has-text-grey has-text-centered">
                          <p>
                              <b-icon
                                  icon="emoticon-sad"
                                  size="is-large">
                              </b-icon>
                          </p>
                          <p>Nenhum Registro.</p>
                      </div>
                  </section>
              </template>
              </b-table>
            </div>

            <!-- <div class="field">
              <b-checkbox v-model="termsConditions">Eu concordo com os termos acima.</b-checkbox>
            </div> -->
          </div>

          <div v-if="loadProgress" class="filed" style="margin-top: 25px;">
            <p style="margin-bottom: 15px; text-align: center;">Carregando informações do time. Isso pode demorar um pouco! Por favor, não recarregue a página enquanto nao encerrar!</p>
            <progress class="progress is-small is-primary" max="100">15%</progress>
          </div>
          
        </section>
        <footer class="modal-card-foot">
          <b-button type="is-danger" @click="$parent.close()" :disabled="loadProgress" outlined>Cancelar</b-button>
          <b-button type="is-success" @click="addTeam()" :disabled="selected.length == 0">Adicionar</b-button>
        </footer>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'addTeam',
  props: {
    'idUser': {
      type: Number,
      default: () => 0
    },
    'teams': {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selected: [],
      termsConditions: false,
      loadProgress: false,
      showNewFriend: false,
      showResultsFriends: false,
      searchTeaminput: '',
      timesFriends: [],
    }
  },
  computed: {
    ...mapGetters([ 'userId' ]),
  },
  methods: {
    ...mapActions([
      "getLigasTimesCartola", "getAdminTimesCartola"
    ]),
    addTeam: function() {
      // let sum = this.teams.length + this.selected.length
      // if (sum > 7) {
      //   this.$toasted.show(`Você selecionou ${sum - 7} times acima do limite!`, {
      //     position: 'top-center',
      //     duration: 3000,
      //     type: 'error',
      //     className: 'toasted-class'
      //   })
      //   return;
      // }

      this.loadProgress = true
      this.$http.post(url + '/ligas/times/' + this.idUser + '/add', {
        id_time: this.selected.map(el => el.time_id)
      })
      .catch(err => console.log('HTTP_ERROR'))
      .then(async res => {
        if (res.data.status != 'error') {
          
          let dadosTime = res.data.data
          let erros = dadosTime.filter(el => {
            if (typeof el == 'string') {
              let _a = el.split('-')[1]
              let elm = this.selected.filter(o => o.time_id == _a)[0]
              this.$toasted.show(`${elm.nome} já está cadastrado!`, {
                position: 'top-center',
                duration: 4000,
                type: 'error',
                className: 'toasted-class'
              })

              return el
            }
          })
          if (erros.length > 0) {
            this.loadProgress = false
            this.$parent.close()
            return;
          }

          this.getLigasTimesCartola(this.idUser)

          this.$toasted.show('Time Adicionado!', {
            position: 'top-center',
            duration: 3000,
            type: 'success',
            className: 'toasted-class'
          })
          this.loadProgress = false
          this.$parent.close()
        } 
        else {
          
          let msg = 'Ocorreu um erro interno ao carregar os dados'
          if (res.data.message == 'Already exist.') msg = 'Alguns Times já estão cadastrados!'

          this.$toasted.show(msg, {
            position: 'top-center',
            duration: 3000,
            type: 'error',
            className: 'toasted-class'
          })

          this.loadProgress = false
          this.$parent.close()
        }
        
      })
    },
    async search() {
  
      let loader = this.$loading.show()

      let response = await this.getAdminTimesCartola({
        adminUserId: this.userId,
        userId: this.idUser,
        searchTeam: this.searchTeaminput
      })

      if (response.status != 'error') {
        this.timesFriends = response.data
        this.showResultsFriends = true
      }
      loader.hide()
    },
    selectTeam(time, index) {
      this.selected.push(time)
      this.timesFriends = this.timesFriends.filter(el => el.time_id != time.time_id)
    }
  }
}
</script>

<style scoped>
#adminAddTeam .friend-modal {
  padding: 10px;
  border-radius: 7px;
  background-color: #4d4d4d;
  height: 250px;
  overflow-y: auto;
}
#adminAddTeam .friend-img-escudo {
  position: relative;
}
#adminAddTeam .friend-img-perfil {
  position: relative;
  left: -15px;
  border-radius: 50%;
  /* border: 1px solid #ebebeb; */
  border: 2px solid #4d4d44;
}
#adminAddTeam .friend-p {
  font-size: 16px;
}
#adminAddTeam .friend-small {
  position: relative;
  top: -3px;
  color: #adadad;
}
</style>
