<template>
  <section id="userTeam">

    <div>
      <b-modal
        :active.sync="loadProgress"
        :width="400"
        :can-cancel="[false, false, false]"
      >
        <div class="modal-card" style="width: auto">
          <section class="modal-card-body">
            <div class="field" style="margin-top: 25px;">
              <p style="margin-bottom: 15px; text-align: center;">
                Carregando informações, por favor, não recarregue a página até
                que o processo seja concluído.
              </p>
              <progress class="progress is-small is-primary" max="100"
                >15%</progress
              >
            </div>
          </section>
        </div>
      </b-modal>
    </div>

    <div class="columns is-multiline has-background-bluedark2">

      <div class="column is-full toolbar">
        <b-button type="is-purple" size="is-small" @click="modalAddTeam = true">Adicionar Time</b-button>
      </div>

      <div class="column is-full">

        <b-table
          blueDark2
          v-if="renderTeams"
          :data="getLigasTimesUser"
          :loading="loading"
          paginated
          per-page="10"
        >
          <template slot-scope="props">

            <b-table-column field="id_escala" label="Id">
              {{ props.row.id_escala }}
            </b-table-column>

            <b-table-column field="id_cartola" label="Id Cartola">
              {{ props.row.id_cartola }}
            </b-table-column>

            <b-table-column field="url_escudo_svg" label="" width="120">
              <div class="team-align-img">
                <img
                  :src="props.row.url_camisa_svg"
                  class="team-camisa"
                />
                <img
                  :src="props.row.url_escudo_svg"
                  class="team-escudo"
                />
              </div>
            </b-table-column>

            <b-table-column field="nm_time_cartola" label="Nome Time" sortable searchable>
              {{ props.row.nm_time_cartola }}
            </b-table-column>

            <b-table-column field="nm_user_cartola" label="Nome Usuário" sortable searchable>
              {{ props.row.nm_user_cartola }}
            </b-table-column>

            <b-table-column field="autenticado" label="Autenticação" centered>
              <b-icon v-if="props.row.autenticado" type="is-success" pack="fas" size="is-medium" icon="check"></b-icon>
              <b-icon v-if="!props.row.autenticado" type="is-danger" pack="fas" size="is-medium" icon="times"></b-icon>
            </b-table-column>

            <b-table-column field="acoes" label="Ações" centered>
              <b-tooltip v-if="!props.row.autenticado" label="Habilitar Time" type="is-success" position="is-bottom" animated>
                <b-button @click="habilitarTeam(props.row)" type="is-success" size="is-small" icon-right="check" outlined></b-button>
              </b-tooltip>
              <b-tooltip label="Excluir Time" type="is-danger" position="is-bottom" animated>
                <b-button @click="deleteTeam(props.row)" type="is-danger" size="is-small" icon-right="delete" outlined></b-button>
              </b-tooltip>
            </b-table-column>

          </template>

          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                </p>
                <p>Nenhum Registro.</p>
              </div>
            </section>
          </template>
        </b-table>

      </div>
    </div>

    <b-modal :active.sync="modalAddTeam" :width="450" :can-cancel="[true, true, false]">
      <addTeams :idUser="idUser" v-on:addTeam="getLigasTimesCartola(idUser)"></addTeams>
    </b-modal>

  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Moment from 'moment';
import addTeams from "./AddTeamVodal";

export default {
  name: 'Teams',
  components: {
    addTeams
  },
  props: {
    'idUser': {
      type: Number,
      default: () => 0
    }
  },
  data() {
    return {
      loading: false,
      loadProgress: false,
      renderTeams: false,
      modalAddTeam: false
    }
  },
  computed: {
    ...mapGetters([ 'userId', 'getLigasTimesUser' ]),
  },
  async created() {

    let loader = this.$loading.show()

    this.renderTeams = false
    await this.getLigasTimesCartola(this.idUser)
    this.renderTeams = true

    loader.hide()
  },
  methods: {
    ...mapActions(['getLigasTimesCartola', 'enableAdminTeam', 'disableAdminTeam']),
    habilitarTeam (time) {
      
      this.$buefy.dialog.confirm({
        title: 'Habilitar Time',
        type: 'is-success',
        hasIcon: true,
        icon: 'check',
        iconPack: 'fa',
        message: 'Tem certeza que deseja habilitar este time?',
        canCancel: true,
        confirmText: 'Sim',
        cancelText: 'Não',
        size: 'is-small', 
        onConfirm: async () => {

          this.loadProgress = true

          try {
            let res = await this.enableAdminTeam({
              adminUserId: this.userId,
              userId: this.idUser,
              teamId: time.id_escala
            })

            if (res.data) {
              this.getLigasTimesCartola(this.idUser)

              this.$toasted.show('Time Habilitado Com Sucesso!', {
                position: 'top-center',
                duration: 3000,
                type: 'success',
                className: 'toasted-class'
              })
            } 
          } catch (err) {
            if (err.status == 400) {
              this.$toasted.error('Ocorreu um erro ao tentar habilitar o time!', {
                position: 'top-center',
                duration: 3000,
              })
            }
            else {
              this.$toasted.error('Erro ao Excluir Time', {
                position: 'top-center',
                duration: 3000,
              })
            }
          }
        
          this.loadProgress = false
        }
      })
    },
    deleteTeam (time) {
      this.$buefy.dialog.confirm({
        title: 'Excluir Time',
        type: 'is-danger',
        hasIcon: true,
        icon: 'times-circle',
        iconPack: 'fa',
        message: 'Tem certeza que deseja excluir este time?',
        canCancel: true,
        confirmText: 'Sim',
        cancelText: 'Não',
        size: 'is-small', 
        onConfirm: async () => {

          this.loadProgress = true

          try {
            let res = await this.disableAdminTeam({
              adminUserId: this.userId,
              userId: this.idUser,
              teamId: time.id_escala
            })

            if (res.data) {
              this.getLigasTimesCartola(this.idUser)

              this.$toasted.show('Time Excluido Com Sucesso!', {
                position: 'top-center',
                duration: 3000,
                type: 'success',
                className: 'toasted-class'
              })
            } 
          } catch (err) {
            if (err.status == 400) {
              this.$toasted.error('Time está inscrito em uma Liga!', {
                position: 'top-center',
                duration: 3000,
              })
            }
            else {
              this.$toasted.error('Erro ao Excluir Time', {
                position: 'top-center',
                duration: 3000,
              })
            }
          }
        
          this.loadProgress = false
        }
      })
    }
  }
}
</script>

<style scoped>
#userTeam .team-align-img {
  position: relative;
}
#userTeam .team-camisa {
  width: 50px;
  position: relative;
  top: 50%;
  left: 0%;
  transform: translate(10%, 0%)
}
#userTeam .team-escudo {
  width: 40px;
  position: relative;
  top: 50%;
  left: 0;
  transform: translate(-35%, 0%)
}
#userTeam .toolbar {
  text-align: right;
  padding: 1.5em;
}
</style>