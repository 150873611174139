<template>
  <section id="userTrade">

    <div>
      <b-modal
        :active.sync="loadProgress"
        :width="400"
        :can-cancel="[false, false, false]"
      >
        <div class="modal-card" style="width: auto">
          <section class="modal-card-body">
            <div class="field" style="margin-top: 25px;">
              <p style="margin-bottom: 15px; text-align: center;">
                Carregando informações, por favor, não recarregue a página até
                que o processo seja concluído.
              </p>
              <progress class="progress is-small is-primary" max="100"
                >15%</progress
              >
            </div>
          </section>
        </div>
      </b-modal>
    </div>

    <div class="columns is-multiline has-background-bluedark2">

      <div class="column is-narrow">
        <div class="block-info-saldo sem-bd-left">
          <label>Saldo para retirada</label>
          <p>R$ {{ adminWallet.saldo_wallet }}</p>
        </div>
      </div>
      <div class="column is-narrow">
        <div class="block-info-saldo sem-bd-left">
          <label>Crédito para jogo</label>
          <p>R$ {{ adminWallet.saldo_bonus }}</p>
        </div>
      </div>
<!--
      <div class="column is-narrow">
        <div class="block-info-saldo sem-bd-left">
          <label>Saldo em Jogo</label>
          <p>R$ {{ adminWallet.saldo_ligas }}</p>
        </div>
      </div>
-->
      <div class="column is-narrow">
        <div class="block-info-saldo sem-bd-left">
          <b>
            <label>SALDO TOTAL</label>
            <p>R$ {{ ((adminWallet.saldo_wallet) ? adminWallet.saldo_wallet : 0) + ((adminWallet.saldo_bonus) ? adminWallet.saldo_bonus : 0) }}</p>
          </b>
        </div>
      </div>
      <div class="column is-auto toolbar">
        <b-button type="is-purple" size="is-small" @click="modalAddTrade = true">Adicionar Transação</b-button>
      </div>
      
      <div class="column is-full">

        <b-tabs type="is-toggle" expanded>
          <b-tab-item label="Transações financeiras" icon="money-bill-wave" icon-pack="fas">
            <div class="trade-table">
              <b-table
                blueDark2
                v-if="renderTrades"
                :data="trades"  
                :mobile-cards="true"
                :paginated="true"
                :per-page="9">

                <!-- default-sort="id"
                default-sort-direction="desc" -->

                <template slot-scope="props" slot="header">
                  <div v-if="props.column.sortable" class="label-table centralize-row">
                    <span v-if="props.column.sortable">
                      <b-icon icon="sort" pack="fas" size="is-small"></b-icon>
                    </span>
                    <p>{{ props.column.label }}</p>
                  </div>
                  <div v-else class="label-table centralize-row">
                    <p>{{ props.column.label }}</p>
                  </div>
                </template>

                <template slot-scope="props">

                    <b-table-column field="id" label="Data e Hora" left sortable searchable>
                      {{ props.row.tradeTime }}
                    </b-table-column>

                    <b-table-column field="type_name" label="Tipo" width="100" left centered searchable>
                      {{ props.row.type_name }}
                    </b-table-column>

                    <b-table-column field="integration" label="Integração" centered searchable>
                      {{ props.row.integration }}
                      <p v-if="props.row.log">{{ props.row.log }}</p>
                    </b-table-column>

                    <b-table-column field="status" label="Status" width="100" centered searchable>
                      <b-tooltip :label="props.row.error" :active="(props.row.error) ? true : false" :type="(props.row.status == 'pendente') ? 'is-warning' : 'is-danger'" position="is-bottom" animated>
                        <span :class="{'tag is-danger': props.row.status == 'rejeitado', 'tag is-warning': props.row.status == 'pendente', 'tag is-info': props.row.status == 'em análise', 'tag is-black': props.row.status == 'devolvido', 'tag is-success': props.row.status == 'aprovado' && props.row.type !== 3, 'tag is-primary': props.row.status == 'aprovado' && props.row.type == 3}">
                          {{ props.row.status }}
                        </span>
                      </b-tooltip>
                    </b-table-column>

                    <b-table-column field="points" label="Valor" width="100" right numeric>
                      {{ props.row.points.toFixed(2) }}
                      <b-icon v-if="props.row.points >= 0" pack="fas" icon="long-arrow-alt-up" type="is-success" size="is-small"></b-icon>
                      <b-icon v-if="props.row.points < 0" pack="fas" icon="long-arrow-alt-down" type="is-danger" size="is-small"></b-icon>
                    </b-table-column>

                    <b-table-column field="saldo" label="Saldo para retirada" right numeric>
                      {{ props.row.saldo.toFixed(2) }}
                    </b-table-column>

                    <b-table-column field="bonus" label="Bônus" right numeric>
                      {{ props.row.bonus.toFixed(2) }}
                      <b-icon v-if="props.row.bonus >= 0" pack="fas" icon="long-arrow-alt-up" type="is-success" size="is-small"></b-icon>
                      <b-icon v-if="props.row.bonus < 0" pack="fas" icon="long-arrow-alt-down" type="is-danger" size="is-small"></b-icon>
                    </b-table-column>

                    <b-table-column field="saldo_bonus" label="Saldo de bônus" right numeric>
                      {{ props.row.saldo_bonus.toFixed(2) }}
                    </b-table-column>

                </template>

                <template slot="empty">
                    <section class="section">
                        <div class="content has-text-grey has-text-centered">
                            <p>
                                <b-icon
                                    icon="emoticon-sad"
                                    size="is-large">
                                </b-icon>
                            </p>
                            <p>Nenhum Registro.</p>
                        </div>
                    </section>
                </template>
              </b-table>
            </div>
          </b-tab-item>

          <b-tab-item label="Transações em jogo" icon="receipt" icon-pack="fas">

            <div class="trade-table">
              <b-table
                blueDark2
                v-if="renderTradesInGame"
                :data="tradesInGame"
                :mobile-cards="true"
                default-sort="datetime"
                default-sort-direction="desc"
                :paginated="true"
                :per-page="7">

                <template slot-scope="props" slot="header">
                  <div v-if="props.column.sortable" class="label-table centralize-row">
                    <span v-if="props.column.sortable">
                      <b-icon icon="sort" pack="fas" size="is-small"></b-icon>
                    </span>
                    <p>{{ props.column.label }}</p>
                  </div>
                  <div v-else class="label-table centralize-row">
                    <p>{{ props.column.label }}</p>
                  </div>
                </template>

                <template slot-scope="props">

                  <b-table-column field="id" label="Data e Hora" width="200" left sortable searchable>
                    {{ props.row.tradeTime }}
                  </b-table-column>

                  <b-table-column field="influencer_name" label="Organizador" searchable>
                    <div style="width: max-content;">
                      {{ props.row.influencer_name }}
                    </div>
                  </b-table-column>

                  <b-table-column field="liga_name" label="Liga" left searchable>
                    <div style="width: max-content;">
                      {{ props.row.liga_name }}
                    </div>
                  </b-table-column>

                  <b-table-column field="liga" label="ID Liga/Sala">
                    <div v-if="props.row.liga" style="width: max-content;">
                      Liga: {{ props.row.liga }} / Sala: {{ props.row.sala }}
                    </div>
                  </b-table-column>

                  <b-table-column field="points" label="Inscrição" right numeric>
                    {{ props.row.points.toFixed(2) }}
                  </b-table-column>

                  <b-table-column field="bonus" label="Inscrição (Bônus)" right numeric>
                    {{ props.row.bonus.toFixed(2) }}
                  </b-table-column>

                  <b-table-column field="team_name" label="Time" width="300" searchable>
                    <div class="time-escudo">
                      <img :src="props.row.escudo" :alt="props.row.escudo">
                    </div>
                    <div class="time-nome">
                      {{ props.row.team_name }}
                    </div>
                  </b-table-column>

                  <b-table-column field="ranking" label="Premiação">
                    <div v-if="props.row.ranking" style="width: max-content;">
                      R$ {{ props.row.ranking_value.toFixed(2).replace(".",",") }}
                      <b-icon v-if="props.row.ranking > 0" :type="(props.row.ranking == 1) ? 'is-ouro' : ((props.row.ranking == 2) ? 'is-prata' : ((props.row.ranking == 3) ? 'is-bronze' : 'is-white'))" pack="fas" icon="medal" size="is-small" style="margin-left: 5px;"></b-icon>
                      {{ props.row.ranking }}º
                    </div>
                  </b-table-column>

                </template>

                <template slot="empty">
                    <section class="section">
                        <div class="content has-text-grey has-text-centered">
                            <p>
                                <b-icon
                                    icon="emoticon-sad"
                                    size="is-large">
                                </b-icon>
                            </p>
                            <p>Nenhum Registro.</p>
                        </div>
                    </section>
                </template>
              </b-table>
            </div>

          </b-tab-item>
        </b-tabs>

      </div>
    </div>

    <b-modal :active.sync="modalAddTrade" :width="450" :can-cancel="[true, true, false]">
      <addTrades :idUser="idUser" :idWallet="adminWallet.id_wallet" v-on:addTrade="loadTrades()"></addTrades>
    </b-modal>

  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Moment from 'moment'
import addTrades from "./AddTradeVodal";

export default {
  name: 'Trade',
  components: {
    addTrades
  },
  props: {
    'idUser': {
      type: Number,
      default: () => 0
    }
  },
  data() {
    return {
      loadProgress: false,
      modalAddTrade: false,
      renderTrades: false,
      renderTradesInGame: false,
      trades: [],
      tradesInGame: [],
      tradesPremiacao: []
    }
  },
  computed: {
    ...mapGetters([ 'userId', 'adminWallet' ]),
  },
  async created() {
    let loader = this.$loading.show()
    await this.loadTrades()
    loader.hide()
  },
  methods: {
    ...mapActions(['getAdminWallet', 'getAdminTrades', 'getAdminTradesByType']),
    async loadTrades() {
      
      this.loadingTable = true

      await this.getAdminWallet({
        adminUserId: this.userId,
        userId: this.idUser
      })

      if (this.adminWallet){

        let loader1 = this.$loading.show()

        this.trades = await this.getAdminTrades({
          adminUserId: this.userId,
          userId: this.idUser,
          walletId: this.adminWallet.id_wallet
        })

        if (this.trades.length > 0){

          let saldo = 0
          let saldo_bonus = 0
          this.trades = this.trades.map((el,index) => {
            
            let tradeTime = Moment(new Date(el.datetime))
            tradeTime = tradeTime.format('DD/MM/YYYY - HH:mm:ss')
            
            saldo += ((el.type !== 1 && el.type !== 3 && el.status !== "rejected") || 
                      (el.type == 3 && el.status == "approved") || 
                      (el.type == 1 && "approved,paid".indexOf(el.status) !== -1)) ? el.points : 0

            saldo_bonus += ((el.type !== 1 && el.type !== 3 && el.status !== "rejected") || 
                      (el.type == 3 && el.status == "approved") || 
                      (el.type == 1 && "approved,paid".indexOf(el.status) !== -1)) ? el.bonus : 0

            if (el.status){
              el.status = el.status.toLowerCase()
              if (el.status == "approved" || el.status == "paid"){
                el.status = "aprovado"
              } else if (el.status == "rejected") {
                el.status = "rejeitado"
              } else if ("pending,in_process".indexOf(el.status) !== -1) {
                el.status = "pendente"
              } else if (el.status == "balance") {
                el.status = "em análise"
              } else if (el.status == "refunded") {
                el.status = "devolvido"
              }
            }

            return {...el, ordem: index+1, tradeTime, saldo, saldo_bonus}
          })

          this.trades.sort(
            function (a, b) {
              if (a.ordem && b.ordem){
                if (a.ordem < b.ordem) return 1
                else if (a.ordem > b.ordem) return -1
                else return 0
              } else return 0
            }
          );

          this.renderTrades = true
        }
        loader1.hide()

        let loader2 = this.$loading.show()

        this.tradesInGame = await this.getAdminTradesByType({
          adminUserId: this.userId,
          userId: this.idUser,
          walletId: this.adminWallet.id_wallet,
          typeId: 2
        })

        this.tradesPremiacao = await this.getAdminTradesByType({
          adminUserId: this.userId,
          userId: this.idUser,
          walletId: this.adminWallet.id_wallet,
          typeId: 3
        })

        if (this.tradesInGame.length > 0){

          this.tradesInGame = this.tradesInGame.map((el,index) => {
            
            let tradeTime = Moment(new Date(el.datetime))
            tradeTime = tradeTime.format('DD/MM/YYYY - HH:mm:ss')
            
            let premiacao = this.tradesPremiacao.find(premio => (premio.liga == el.liga && premio.team == el.team))
            if (premiacao){
              el.ranking = premiacao.ranking
              el.ranking_value = premiacao.ranking_value
            }

            return {...el, tradeTime: tradeTime}
          })

          this.renderTradesInGame = true
        }
        loader2.hide()
      
      } else {

        this.$toasted.error("Carteira não identificada!", {
          position: "top-center",
          duration: 5000,
          type: 'danger'
        });
        
      }
    }
  }
}
</script>

<style scoped>

#userTrade .trade-table {
  width: 100%;
  height: auto !important;
  overflow-y: auto;
  /* background-color: #4d4d4d; */
  border-radius: 7px;
  padding: 5px;
}

#userTrade .label-table,
#userTrade .label-table i {
  font-size: 0.9rem !important;
}

#userTrade .time-escudo{
  display: inline-block;
  height: auto;
  width: 9%;
  margin-right: 5px; 
  vertical-align: middle;
}
#userTrade .time-nome{
  display: inline-block;
  vertical-align: middle;
  width: 80%;
}
#userTrade .toolbar {
  text-align: right;
  padding: 1.5em;
}

#userTrade .block-info-saldo {
  padding: 0 1.5em;
  border-left: 1px solid #00f0f8;
  border-right: 1px solid #00f0f8;
  text-align: center;
}
#userTrade .block-info-saldo p {
  font-weight: bold;
  font-size: 1.5em;
}
#userTrade .block-info-saldo label {
  font-size: 0.9em;
}
#userTrade .sem-bd-left {
  border-left: none;
  padding-left: 0.5em;
}

</style>