<template>
  <section id="userTransactions">
    <div class="columns is-multiline has-background-bluedark2">
      <div class="column is-full">
        <b-table
          blueDark2
          v-if="render"
          :data="transactions"
          :mobile-cards="true"
          :paginated="true"
          :per-page="10"
        >
          <template slot-scope="props" slot="header">
            <div
              v-if="props.column.sortable"
              class="label-table centralize-row"
            >
              <span v-if="props.column.sortable">
                <b-icon icon="sort" pack="fas" size="is-small"></b-icon>
              </span>
              <p>{{ props.column.label }}</p>
            </div>
            <div v-else class="label-table centralize-row">
              <p>{{ props.column.label }}</p>
            </div>
          </template>

          <template slot-scope="props">
            <b-table-column
              field="tradeTime"
              label="Data e Hora"
              left
              sortable
              searchable
            >
              {{ props.row.tradeTime }}
            </b-table-column>

            <b-table-column
              field="id_reference"
              label="Referência"
              centered
              searchable
            >
              {{ props.row.id_reference }}
            </b-table-column>

            <b-table-column
              field="id_payment"
              label="Pagamento"
              centered
              searchable
            >
              {{ props.row.id_payment }}
            </b-table-column>

            <b-table-column
              field="id_transaction"
              label="Transação"
              centered
              searchable
            >
              {{ props.row.id_transaction }}
            </b-table-column>

            <b-table-column
              field="integracao"
              label="Integração"
              centered
              searchable
            >
              {{ props.row.integracao }}
            </b-table-column>

            <b-table-column field="tipo" label="tipo" centered searchable>
              {{ props.row.tipo }}
            </b-table-column>

            <b-table-column field="produto" label="Produto" centered searchable>
              {{ props.row.produto }}
            </b-table-column>

            <b-table-column field="valor" label="Valor" centered searchable>
              {{ props.row.valor.toFixed(2) }}
            </b-table-column>

            <b-table-column field="status" label="Status" centered searchable>
              {{ props.row.status }}
            </b-table-column>

            <b-table-column
              field="mensagem"
              label="Mensagem"
              centered
              searchable
            >
              {{ props.row.mensagem }}
            </b-table-column>
          </template>

          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                </p>
                <p>Nenhum Registro.</p>
              </div>
            </section>
          </template>
        </b-table>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Moment from "moment";

export default {
  name: "Transactions",
  props: {
    idUser: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      render: false,
      transactions: [],
    };
  },
  async created() {
    let loader = this.$loading.show();

    this.$http
      .get(url + "/payment/get/" + this.idUser)
      .catch((err) => {
        loader.hide();
        console.log(err);
      })
      .then(async (response) => {
        if (response.data.data) {
          this.transactions = response.data.data;

          this.transactions = this.transactions.map((el, index) => {
            let tradeTime = Moment(new Date(el.callback));
            tradeTime = tradeTime.format("DD/MM/YYYY - HH:mm:ss");

            return { ...el, tradeTime };
          });
          this.render = true;
        }
        loader.hide();
      });
  },
};
</script>
