<template>
  <section id="adminAddTrade">
    
    <div v-if="renderVodal" class="modal-card" style="width: auto; height: 85vh;">
      
      <header class="modal-card-head">
        <p class="modal-card-title">Inclusão de Transação na Carteira</p>
      </header>

      <section class="modal-card-body">
        
        <div v-if="!loadProgress">
          <b-field label="Tipo">
            <template slot="label">
              <p>Tipo</p>
              <small>Selecione um tipo para definir a operação da Transação</small>
            </template>
            <b-select
              ref="tipo"
              v-model="trade.type"
              expanded
              required
            >
              <option v-for="type of types" 
                :key="type.id_type_trade" 
                :value="type"
              >{{type.nm_type_trade}} - {{type.ds_type_trade}}
              </option>
            </b-select>
          </b-field>

          <b-field label="Descrição">
            <template slot="label">
              <p>Descrição</p>
              <small>Descreva o motivo da transação</small>
            </template>
            <b-input ref="descricao" v-model="trade.log" maxlength="100" required expanded></b-input>
          </b-field>

          <b-field label="Valor Depósito">
            <template slot="label">
              <p>Valor Depósito</p>
              <small>Informe o valor da transação refernte a um depósito</small>
            </template>
            <b-input ref="valor" type="number" v-model="trade.value_deposito" :icon="(trade.type.sign_type_trade) ? ((trade.type.sign_type_trade == '+') ? 'plus' : 'minus') : 'plus'" step="0.01" min="0" expanded></b-input>
          </b-field>

          <b-field label="Valor Prêmiação">
            <template slot="label">
              <p>Valor Prêmiação</p>
              <small>Informe o valor da transação referente a uma premiação</small>
            </template>
            <b-input ref="valor" type="number" v-model="trade.value_premio" :icon="(trade.type.sign_type_trade) ? ((trade.type.sign_type_trade == '+') ? 'plus' : 'minus') : 'plus'" step="0.01" min="0" expanded></b-input>
          </b-field>

          <b-field label="Valor Bônus">
            <template slot="label">
              <p>Valor Bônus</p>
              <small>Informe o valor da transação referente a um bônus</small>
            </template>
            <b-input ref="valor_bonus" type="number" v-model="trade.value_bonus" :icon="(trade.type.sign_type_trade) ? ((trade.type.sign_type_trade == '+') ? 'plus' : 'minus') : 'plus'" step="0.01" min="0" expanded></b-input>
          </b-field>
        </div>

        <div v-if="loadProgress" class="filed" style="margin-top: 25px;">
          <p style="margin-bottom: 15px; text-align: center;">Carregando informações do time. Isso pode demorar um pouco! Por favor, não recarregue a página enquanto nao encerrar!</p>
          <progress class="progress is-small is-primary" max="100">15%</progress>
        </div>
        
      </section>

      <footer class="modal-card-foot">
        <b-button type="is-danger" @click="$parent.close()" :disabled="loadProgress" outlined>Cancelar</b-button>
        <b-button type="is-success" @click="addTrade()">Adicionar</b-button>
      </footer>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'addTrade',
  props: {
    'idUser': {
      type: Number,
      default: () => 0
    },
    'idWallet': {
      type: Number,
      default: () => 0
    }
  },
  data() {
    return {
      loadProgress: false,
      renderVodal: false,
      types: [],
      trade: {
        type: '',
        log: '',
        value_deposito: 0,
        value_premio: 0,
        value_bonus: 0
      }
    }
  },
  computed: {
    ...mapGetters([ 'userId', 'typesTrade' ]),
  },
  async created() {

    let loader = this.$loading.show()

    this.types = await this.getAdminTypeTrade({
      adminUserId: this.userId
    })

    loader.hide()
    this.renderVodal = true
  },
  methods: {
    ...mapActions(["getAdminTypeTrade", "addAdminMovTrade"]),
    async addTrade() {

      this.loadProgress = true

      if (this.validTrade(true)){
        
        let response = await this.addAdminMovTrade({
          adminUserId: this.userId,
          userId: this.idUser,
          walletId: this.idWallet,
          trade: this.trade
        })

        if (response.status == 'success'){
          if (response.data.affectedRows == 1){
            this.$toasted.success("Transação incluída com sucesso!", {
              duration: 3000,
              position: "top-center"
            });
            this.$emit('addTrade')
          }
        } else {
          this.$toasted.error("Ocorreu um problema ao tentar incluir a transação!", {
            duration: 3000,
            position: "top-center"
          });
        }

        this.$parent.close()
      }

      this.loadProgress = false
    },
    validTrade() {

      let valid = true

      this.$refs.tipo.checkHtml5Validity();
      this.$refs.descricao.checkHtml5Validity();
      this.$refs.valor.checkHtml5Validity();
      this.$refs.valor_bonus.checkHtml5Validity();

      let msgAlert = ''

      if (!this.$refs.tipo.isValid){
        msgAlert += ((msgAlert !== '') ? ', ' : '') + 'Tipo'
      }
      if (!this.$refs.descricao.isValid){
        msgAlert += ((msgAlert !== '') ? ', ' : '') + 'Descrição'
      }
      if (!this.$refs.valor.isValid){
        msgAlert += ((msgAlert !== '') ? ', ' : '') + 'Valor'
      }
      if (!this.$refs.valor_bonus.isValid){
        msgAlert += ((msgAlert !== '') ? ', ' : '') + 'Valor Bônus'
      }
      if (msgAlert !== "") {
        msgAlert = 'Campo(s) com dados inconsistentes: '+msgAlert
      }

      if (msgAlert !== '') {

        valid = false
        
        this.$buefy.dialog.alert({
          title: "ATENÇÃO!",
          message: msgAlert,
          type: "is-warning",
          hasIcon: true
        });
      }

      return valid
    }
  }
}
</script>

<style scoped>
</style>
